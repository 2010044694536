import { createRootRoute, createRoute } from '@tanstack/react-router';
import ExitPrompt from 'common/components/exitPrompt';
import { ErrorBoundary } from 'common/components/errorBoundary';
import ErrorPage from 'features/errorPage';
import Logout from 'features/Logout';
import MainPanel from 'features/mainPanel';
import LoggedInRoutesPanel from 'features/loggedInRoutesPanel';
import PendingComponentLoader from 'common/components/pendingComponentLoader';
import { redirectToTicketOrServicePortal as isRedirectToTicket } from 'features/TicketPanel/ticketloader';
import { ROUTES_PATHS } from './constants';

const createLazyRoute = (path) => (config) => {
  const route = createRoute({
    path,
    shouldReload: false,
    ...config,
  });
  return config.lazy ? route.lazy(config.lazy) : route;
};

const withErrorBoundary = (Component) => () =>
  (
    <ErrorBoundary>
      <Component />
    </ErrorBoundary>
  );

const withLoggedIn = (Component) => () =>
  (
    <LoggedInRoutesPanel>
      <Component />
    </LoggedInRoutesPanel>
  );

const routesConfig = {
  root: {
    path: '/',
    exact: true,
    component: () => (
      <ExitPrompt>
        <MainPanel />
      </ExitPrompt>
    ),
    pendingComponent: PendingComponentLoader,
    children: {
      login: {
        path: ROUTES_PATHS.LOGIN_URL,
        lazy: () => import('./routes/login.lazy').then((d) => d.Route),
      },
      forgotPassword: {
        path: ROUTES_PATHS.FORGOT_PASSWORD_URL,
        lazy: () => import('./routes/forgotPassword.lazy').then((d) => d.Route),
      },
      checkMail: {
        path: ROUTES_PATHS.CHECKMAIL_URL,
        lazy: () => import('./routes/checkMail.lazy').then((d) => d.Route),
      },
      error404: {
        path: ROUTES_PATHS.PAGE404_URL,
        component: withErrorBoundary(ErrorPage),
      },
      logout: {
        path: ROUTES_PATHS.LOGOUT_URL,
        component: withLoggedIn(Logout),
      },
      template: {
        path: ROUTES_PATHS.TEMPLATE_URL,
        lazy: () => import('./routes/templateBuilder.lazy').then((d) => d.Route),
      },
      templateList: {
        path: ROUTES_PATHS.TEMPLATE_LIST_URL,
        lazy: () => import('./routes/templateList.lazy').then((d) => d.Route),
      },
      settings: {
        path: ROUTES_PATHS.SETTINGS_URL,
        lazy: () => import('./routes/settings.lazy').then((d) => d.Route),
      },
      ticket: {
        path: ROUTES_PATHS.TICKET_URL,
        loader: isRedirectToTicket,
        lazy: () => import('./routes/ticket.lazy').then((d) => d.Route),
      },
      externalPages: {
        path: ROUTES_PATHS.EXTERNAl_PAGES_URL,
        lazy: () => import('./routes/externalPage.lazy').then((d) => d.Route),
      },
      queue: {
        path: ROUTES_PATHS.BASE_PATH,
        lazy: () => import('./routes/queue.lazy').then((d) => d.Route),
      },
      connect: {
        path: ROUTES_PATHS.CONNECT,
        lazy: () => import('./routes/connect.lazy').then((d) => d.Route),
      },
      integrations: {
        path: ROUTES_PATHS.INTEGRATIONS,
        lazy: () => import('./routes/integrations.lazy').then((d) => d.Route),
      },
    },
  },
};

const createRoutesFromConfig = (config, parentRoute) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const routes = Object.entries(config).map(([_, routeDef]) => {
    const { children, ...routeConfig } = routeDef;
    const route = createLazyRoute(routeDef.path)({
      getParentRoute: () => parentRoute,
      ...routeConfig,
    });

    if (children) {
      route.addChildren(Object.values(createRoutesFromConfig(children, route)));
    }

    return route;
  });

  return routes;
};

const createRootRouteWithConfig = () => {
  const rootRoute = createRootRoute();
  const routes = createRoutesFromConfig(routesConfig, rootRoute);
  rootRoute.addChildren(routes);
  return rootRoute;
};

export const routeConfig = createRootRouteWithConfig;
